import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import Layout from '../layout'
import { SEO, Overview, Feature, More, Action, Benefits } from '../components'

import SDKsIcon from '../assets/icons/sdks.svg'
import CloudIcon from '../assets/icons/cloud.svg'
import TechSupportIcon from '../assets/icons/tech-support.svg'

import OmnichannelOgImage from '../assets/images/omnichannel-seo-image.png'

const Omnichannel = () => {
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Omnichannel APIs"
        description="The entire communication in one clean interface"
        image={OmnichannelOgImage}
      />
      <div className="u-bg-black-3">
        <Overview
          title="Omnichannel APIs"
          subtitle={
            <Fragment>
              The entire communication <br /> in one clean interface
            </Fragment>
          }
          image={{
            name: 'omnichannelOverview',
            maxHeight: 470,
            goDown: true,
          }}
          features={[
            {
              title: 'Open Chat Protocol',
              description:
                'A simple yet powerful protocol to manage them all. Messages, rich content, files and more all embedded in one API.',
              externalLink: `${process.env.GATSBY_DOCS_URL}messaging/`,
            },
            {
              title: 'Messaging Mode',
              description:
                'Handle both real-time and async communication with just one protocol by switching to Messaging Mode.',
              externalLink: `${
                process.env.GATSBY_PRODUCT_WEBSITE_URL
              }messaging-mode/`,
            },
            {
              title: 'Real-time Messaging',
              description:
                'All LiveChat Messaging APIs come with full support for two transports, socket and typical web requests.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }messaging#rtm-api-vs-web-api`,
            },
          ]}
          button={{
            label: 'See Messaging APIs docs',
            link: `${process.env.GATSBY_DOCS_URL}messaging/`,
          }}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-xl u-Pb-xs">
        <Feature
          title={
            <div className="u-Mt-2xl">
              One common interface <br /> for all the channels
            </div>
          }
          description={
            <>
              Omnichannel APIs let you integrate any channel directly into
              LiveChat. All the communication is handled by a common protocol,
              and displayed in the unified{' '}
              <Link to="/open-agent-app/">Open Agent App</Link> view.
            </>
          }
          image="omnichannelAgentApp"
        />
        <Feature
          inverse
          title={
            <Fragment>
              Native support within <br /> all LiveChat applications
            </Fragment>
          }
          description="By default, all the integrated channels are natively supported in LiveChat web, mobile and desktop apps. Integrate it once, and access it everywhere."
          image="designSystem"
        />
      </div>
      <div className="u-bg-black2 u-Pt-xl u-Pb-xs">
        <Benefits
          content={[
            {
              icon: CloudIcon,
              label: 'RTM & Web transports',
              description:
                'Exchangable transports with full support for webhooks & pushes.',
            },
            {
              icon: SDKsIcon,
              label: 'Shipped with SDKs',
              description:
                'Get the same Go and JavaScript SDKs we use to build our integrations.',
            },
            {
              icon: TechSupportIcon,
              label: 'Technical support',
              description:
                'Our highly skilled support teams are ready to get you up and running.',
            },
          ]}
        />
      </div>
      <div className="u-Pt-2xl">
        <Feature
          title={
            <>
              Powers the integration with <br /> Apple Business Chat
            </>
          }
          description="We believe in dogfooding, so our native channel integrations use the same APIs we give you. See how we integrated Apple Business Chat with LiveChat using Omnichannel APIs."
          image="appleBusinessChat"
          link={{
            label: 'See the Case Study',
            to: `/updates/apple-business-chat-case-study/`,
          }}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-xl u-Pb-2xl">
        <More
          label={
            <Fragment>
              See other
              <span className="u-text-mark u-border-red"> Platform </span>
              products
            </Fragment>
          }
          links={[
            {
              title: 'Programmable Chat',
              description:
                'Open messaging protocol for all types of communication.',
              link: '/programmable-chat/',
            },
            {
              title: 'Reporting APIs',
              description:
                'Build data-driven dashboards and advanced reporting tools.',
              link: '/data-and-reporting/',
            },
            {
              title: 'Open Chat Widget',
              description:
                'Beautiful widget for demanding developers and designers.',
              link: '/omnichannel/',
            },
          ]}
        />
      </div>
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="allChannels"
          title={
            <>
              Have all the messages in one <br /> communication channel
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: 'Open Developer Console',
          }}
          link={{
            link: `${process.env.GATSBY_DOCS_URL}messaging/`,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default Omnichannel
